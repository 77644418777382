.policies {
    min-height: 228px;
    display: flex;
    flex-direction: column;
    margin-top: 110px;
    font-family: Arial;
    font-style: normal;
    flex: 1 0 auto;
    margin-bottom: 118px;
    text-align:  center;

    .policies-label {
        font-weight: 800;
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 70px;
        color: #222224;
        font-family: Arial;
    }

    .policies-button {
        font-family: Arial;
        align-items: center;
        padding: 12px 16px 8px;
        background: #fafafa;
        border-radius: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #222224;
        border: none;
        width: 320px;
        margin: 0 auto 24px auto;
        cursor: pointer;
    }
}
