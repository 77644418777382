.main-page {
  font-family: Arial !important;
  font-style: normal;
  .main-page-wrapper {
    padding: 52px 40px 0 40px;

    display: flex;
    flex-direction: column;
    color: #222224;

    .main-info {
      font-family: Arial;
      margin-top: 80px;
      display: flex;

      .iphone-image {
        width: 50%;
      }

      .main-info-container {
        text-align: left;
        margin-top: 40px;

        .main-label {
          font-size: 64px;
          font-weight: 800;
          line-height: 88px;
          margin: 0 0 24px;
          max-width: 440px;
          font-family: Arial;
        }

        .additional-label {
          font-size: 32px;
          font-weight: 500;
          line-height: 48px;
          max-width: 580px;
          font-family: Arial;
        }
      }
    }
    .additional-info {
      width: 60%;
      margin: 108px auto 80px;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      font-weight: 500;
      font-family: Arial;
    }

    .coming-soon-text {
      font-weight: 800;
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 40px;
      text-align: center;
      font-family: Arial;
    }

    .email-input-block {
      margin-bottom: 240px;
      text-align: center;
      display: flex;
      justify-content: center;

      .thanks-button {
        font-family: Arial;
        padding: 15px 120px;
        color: white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        background: linear-gradient(260.81deg, #ecedf0 0%, #000000 83.05%);
        border-radius: 8px;
        outline: none;
        border: none;
        height: 54px;
        max-width: 532px;
      }

      .join-button {
        font-family: 'Inter';
        font-style: normal;
        color: white;
        background: #8c10fe;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        font-size: 18px;
        padding: 17px 62px 17px 62px;
      }
    }

    .content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      @media (max-width: 820px) {
        flex-direction: column;
      }

      .content-wrapper-block-1 {
        width: 100%;
        display: flex;
        justify-content: space-around;

        @media (max-width: 820px) {
          flex-direction: column;
        }

        .content-block-margin-bottom {
          margin-bottom: 90px;
          @media (max-width: 610px) {
            margin-bottom: 100px;
          }
        }
      }

      .content-wrapper-block-2 {
        width: 100%;
        display: flex;
        justify-content: space-around;

        @media (max-width: 820px) {
          flex-direction: column;
        }

        .content-block-margin-top {
          margin-top: 90px;
          @media (max-width: 610px) {
            margin-top: 0;
            margin-bottom: 100px;
          }
        }
      }
    }

    .content-block {
      display: flex;
      height: 800px;
      // height: 100vh;
      width: 100%;
      max-width: 340px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      place-self: center;
      margin-bottom: 100px;

      .name-block {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .content-icon {
          margin-right: 26px;
          margin-bottom: 5px;
        }

        .content-label {
          font-size: 32px;
          font-weight: 800;
          color: #222224;
          line-height: 32px;
          font-family: Arial;
        }
      }

      .content-image {
        margin-top: 36px;
        max-height: 700px;
        height: fit-content;
        max-width: 100%;
        object-fit: contain;
      }
    }

    .featuring-block {
      padding: 48px 80px 0 80px;

      &.coming {
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 160px;
      }

      .featuring-header {
        font-size: 32px;
        font-weight: 800;
        line-height: 32px;
        color: #222224;
        margin-bottom: 48px;
        text-align: center;
        font-family: Arial;
      }

      .feature-columns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .feature-container {
          background: #fafafa;
          border-radius: 8px;
          min-height: 232px;
          display: flex;
          flex-direction: column;
          width: 47%;
          text-align: left;
          margin-bottom: 48px;

          &.coming {
            background: #ffffff;
            border: 3px dashed #ecedf0;
          }

          .feature-label {
            font-family: Arial;
            font-size: 22px;
            line-height: 32px;
            font-weight: 800;
            color: #222224;
            padding: 24px;
          }

          .feature-text {
            font-family: Arial;
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
            color: #222224;
            padding: 0 86px 24px 24px;
          }
        }
      }
    }
  }
}

.phoneInputdp-questionair {
  input {
    padding: 7px 0px 7px 0px;
  }
}

@media (max-width: 890px) {
  .main-page {
    .main-page-wrapper {
      .main-info {
        flex-direction: column;

        .main-info-container {
          text-align: center;

          .main-label {
            max-width: 100%;
          }

          .additional-label {
            max-width: 100%;
          }
        }

        .iphone-image {
          width: 98%;
        }
      }

      .email-input-block {
        .thanks-button {
          font-size: 12px;
          padding: 15px 30px;
        }
      }

      .content-block {
        flex-direction: column;

        .name-block {
          position: unset;
        }
      }

      .featuring-block {
        padding: 48px 10px;
        .feature-columns {
          flex-direction: column;
          .feature-container {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .main-page-wrapper {
    padding: 52px 20px 0 20px !important;
  }
  .main-label {
    text-align: left;
    font-size: 32px !important;
    line-height: 46px !important;
    margin-bottom: 15px !important;
  }
  .additional-label {
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: left;
  }
  .additional-info {
    font-size: 16px !important;
    margin: 50px 0 !important;
    width: 100% !important;
  }
}

.react-datepicker .react-datepicker__day--selected {
  background-color: #000000 !important;
}
#video-service-calendar .react-datepicker__day--disabled {
  opacity: 0.5;
}

.codeInputStyles {
  display: flex !important;
  justify-content: space-between;
  width: 375px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  input:focus {
    border-color: #222224;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.input-mask {
  background: #ffffff;
  width: 100%;
  border: 2px solid #ecedf0;
  box-sizing: border-box;
  border-radius: 16px;

  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #7f7f82;
  padding: 20px 24px;
  margin-bottom: 16px;
  @media (max-width: 480px) {
    border-radius: 3vw;
    font-size: 4vw;
    line-height: 110%;
    padding: 5vw 5.5vw;
  }
}
