@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Intersemibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
    url('/fonts/Inter/Intermedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Medium Italic'), local('Inter-MediumItalic'),
    url('/fonts/Inter/Intermediumitalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Italic'), local('Inter-Italic'),
    url('/fonts/Inter/Interitalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
    url('/fonts/Inter/Interregular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Black'), local('Inter-Black'),
    url('/fonts/Inter/Interblack.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Semi Bold Italic'), local('Inter-SemiBoldItalic'),
    url('/fonts/Inter/Intersemibolditalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Bold Italic'), local('Inter-BoldItalic'),
    url('/fonts/Inter/Interbolditalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Bold'), local('Inter-Bold'),
    url('/fonts/Inter/Interbold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Black Italic'), local('Inter-BlackItalic'),
    url('/fonts/Inter/Interblackitalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Extra Bold'), local('Inter-ExtraBold'),
    url('/fonts/Inter/Interextrabold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Extra Bold Italic'), local('Inter-ExtraBoldItalic'),
    url('/fonts/Inter/Interextrabolditalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input,
textarea {
  font-family: inherit;
  border: none;
  outline: none;
}

button {
  cursor: pointer;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.DraftEditor-root {
  background: #ecedf0;
  border-radius: 100px;
  padding: 15px;
  color: #7f7f82;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  overflow: auto;
  width: 100%;
  margin-right: 14px;
}
.public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
.RichEditor-editor .public-DraftStyleDefault-pre pre {
  white-space: normal;
}
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  width: 100%;
  overflow-x: auto;
}
.__floater__arrow svg polygon {
  fill: #0A84FF;
}
.fixed-size-circle {
  min-height: 46px;
  min-width: 46px;
  max-width: 46px;
  max-height: 46px;
}
