.support {
  min-height: 306px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 110px;
  align-items: center;
  font-family: Arial;
  font-style: normal;
  flex: 1 0 auto;
  margin-bottom: 118px;
  text-align: center;

  .support-label {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 80px;
    color: #222224;
  }

  .support-button {
    font-family: Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 28px;
    background: #fafafa;
    border-radius: 20px;
    color: #222224;
    border: none;
    max-width: 257px;
    cursor: pointer;
  }

  .support-info {
    font-family: Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #222224;
    margin-bottom: 32px;
  }
}
