.page-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fafafa;
  padding: 120px 15px;
  max-height: 446px;
  position: relative;
  flex: 0 0 auto;

  .coming-soon-text {
    font-size: 32px;
    line-height: 32px;
    color: #222224;
    font-weight: 800;
    margin-bottom: 40px;
    text-align: center;
    font-family: Arial;
  }

  .join-button {
    font-family: 'Inter';
    font-style: normal;
    color: white;
    background: #8c10fe;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    font-size: 18px;
    padding: 17px 62px 17px 62px;
    align-self: center;
  }

  .policy-container {
    position: absolute;
    font-size: 12px;
    line-height: 15px;
    font-family: Inter;
    font-weight: 400;
    color: white;
    bottom: 16px;
    right: 20px;

    .privacy-link {
      text-decoration: underline;
      cursor: pointer;
      font-family: Arial;
      color: #4B4B4D;
    }

    .copyright-info {
      margin-right: 24px;
      font-family: Arial;
      color: #4B4B4D;
    }
  }
}
