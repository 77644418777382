.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 40px 0 40px;
    
    .logo-container {
        display: flex;
        align-items: center;
    }

    .login-button {
        font-family: Arial;
        padding: 8px 16px;
        font-size: 16px;
        color: #222224;
        background-color: #FAFAFA;
        border-radius: 20px;
        border: none;
        margin-top: 10px;
        cursor: pointer;
    }
}

@media (max-width: 890px) {
    
    .header-container {
        .logo-container {
            .logo {
                width: 80%;
            }
        }
    }
}