.copyright {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    font-family: Arial;
    font-style: normal;
    padding: 0 30% 200px;

    .privacy-label {
        font-weight: 800;
        font-size: 32px;
        line-height: 32px;
        color: #222224;
        margin-bottom: 80px;
        margin-top: 140px;
        font-family: Arial;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        font-size: 16px;
        line-height: 25px;
        .text-label {
            font-weight: 700;
            font-family: Arial;
            &.with-margin {
                margin-bottom:20px;
            }
        }
        .text-block {
            font-family: Arial;
            font-weight: 400;
            margin-bottom: 20px;

            &.no-margin {
                margin-bottom: 0;
            }
        }

        .list-block {
            font-family: Arial;
            margin-bottom: 20px;
            margin-left: 20px;
            
            &.inside {
                margin-left: 40px;
            }
        }
    }
}

@media (max-width: 890px) {
    .copyright{
        padding: 0 20px 200px;
    }
}